<script setup>
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Link, useForm } from '@inertiajs/vue3';
import Banner from '../../Components/Banner.vue';
import AppHead from '../../Layouts/AppHead.vue';
import SocialiteButtons from './SocialiteButtons.vue';

defineProps({
  canResetPassword: Boolean,
  status: String,
});

const form = useForm({
  email: '',
  password: '',
  remember: false,
});

const submit = () => {
  form.transform(data => ({
    ...data,
    remember: form.remember ? 'on' : '',
  })).post(route('login'), {
    onFinish: () => form.reset('password'),
  });
};
</script>

<template>
  <AppHead title="Log in"/>

  <Banner/>

  <div class="flex min-h-screen">
    <AuthenticationCard>
      <template #logo>
        <AuthenticationCardLogo/>
      </template>

      <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
        {{ status }}
      </div>

      <SocialiteButtons label="Sign in"/>

      <form class="space-y-6 mt-10 sm:mt-0" @submit.prevent="submit">
        <div>
          <InputLabel for="email" value="Email"/>
          <TextInput
            id="email"
            v-model="form.email"
            autocomplete="username"
            autofocus
            class="mt-2 block w-full"
            required
            type="email"
          />
          <InputError :message="form.errors.email" class="mt-2"/>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <InputLabel for="password" value="Password"/>

            <div class="text-sm">
              <Link v-if="canResetPassword" :href="route('password.request')" class="font-semibold text-blue-600 hover:text-blue-500">
                Forgot password?
              </Link>
            </div>
          </div>

          <TextInput
            id="password"
            v-model="form.password"
            autocomplete="current-password"
            class="mt-2 block w-full"
            required
            type="password"
          />
          <InputError :message="form.errors.password" class="mt-2"/>
        </div>

        <div class="block">
          <label class="flex items-center">
            <Checkbox v-model:checked="form.remember" name="remember"/>
            <span class="ml-2 text-sm text-gray-600">Remember me</span>
          </label>
        </div>

        <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing" class="w-full">
          Log in
        </PrimaryButton>

        <p class="mt-10 text-center text-sm text-gray-500">
          Not a member?
          {{ ' ' }}
          <Link :href="route('register')" class="font-semibold leading-6 text-blue-600 hover:text-blue-500">
            Create an account for free
          </Link>
        </p>
      </form>
    </AuthenticationCard>
  </div>
</template>
